import { Controller } from "@hotwired/stimulus";
import SignaturePad from "signature_pad";

export default class extends Controller {
  static targets = [
    "content",
    "modalTitle",
    "overlay",
    "signatureCanvas",
    "signedTemplate",
    "unsignedTemplate",
  ];

  static values = {
    isCompleted: Boolean
  }

  connect() {
    this.currentRole = null;
    this.currentSignableId = this.element.dataset.signableId;
  }

  openModal(event) {
    // Prevent opening modal if truck visit is completed
    if (this.isCompletedValue) {
      alert("Signatures cannot be added after the truck has gated out.");
      return;
    }

    this.currentRole = event.currentTarget.dataset.signaturesRole;
    this.modalTitleTarget.textContent = `${this.currentRole} Signature`;

    // Show the modal
    this.overlayTarget.style.display = "block";
    this.contentTarget.style.display = "block";

    // Trigger reflow to ensure transitions work
    void this.overlayTarget.offsetWidth;
    void this.contentTarget.offsetWidth;

    // Add active class to trigger transitions
    this.overlayTarget.classList.add("active");
    this.contentTarget.classList.add("active");

    // Initialize or resize the signature pad after the modal is visible
    requestAnimationFrame(() => {
      this.initializeSignaturePad();
    });
  }

  initializeSignaturePad() {
    const canvas = this.signatureCanvasTarget;
    const rect = canvas.parentElement.getBoundingClientRect();
    const dpr = window.devicePixelRatio || 1;

    // Set the canvas size accounting for DPI
    canvas.width = rect.width * dpr;
    canvas.height = rect.height * dpr;

    // Scale canvas CSS size to match parent
    canvas.style.width = `${rect.width}px`;
    canvas.style.height = `${rect.height}px`;

    // Get the context and scale it
    const ctx = canvas.getContext('2d');
    ctx.scale(dpr, dpr);

    // If signature pad exists, destroy it first
    if (this.signaturePad) this.signaturePad.off();

    // Initialize SignaturePad with the canvas
    let slate800 = 'rgb(30, 41, 59)';
    this.signaturePad = new SignaturePad(canvas, {
      backgroundColor: 'rgb(255, 255, 255)',
      penColor: slate800,
      velocityFilterWeight: 0.7,
    });
  }

  closeModal() {
    // Remove active classes to trigger out transitions
    this.overlayTarget.classList.remove("active");
    this.contentTarget.classList.remove("active");

    // Wait for transitions to complete before hiding elements
    setTimeout(() => {
      this.overlayTarget.style.display = "none";
      this.contentTarget.style.display = "none";
      if (this.signaturePad) {
        this.signaturePad.clear();
      }
    }, 200);
  }

  saveSignature() {
    if (this.isCompletedValue) {
      alert("Signatures cannot be added after the truck has gated out.");
      return;
    }

    if (this.signaturePad.isEmpty()) {
      alert("Please provide a signature before saving.");
      return;
    }

    // Disable buttons
    this.toggleSaveButton(false);
    this.toggleCloseButton(false);

    const dataUrl = this.signaturePad.toDataURL("image/png");
    const csrfToken = document.querySelector("[name='csrf-token']").content;

    const data = {
      signature: {
        image_data: dataUrl,
        role: this.currentRole
      }
    };

    const createUrl = this.element.dataset.signaturesCreateUrl;

    // Submit the data
    fetch(createUrl, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: 'same-origin',
      headers: {
        "X-CSRF-Token": csrfToken,
        "Content-Type": "application/json"
      },
    }).then(response => {
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      return response.json();
    }).then((data) => {
      if (data.status === "success") {
        // Update the signature image in the view
        const signatureField = this.element.querySelector(
          `.signature-field span[data-signatures-role="${this.currentRole}"]`
        ).closest('.signature-field');

        if (!signatureField) throw new Error("Signature field not found!");

        // Clone the template
        const template = this.signedTemplateTarget.content.cloneNode(true);

        // Update role text
        const roleText = template.querySelector(".role-text");
        roleText.textContent = `${this.currentRole}: `;

        // Update timestamp
        const timestamp = template.querySelector("[data-signature-timestamp-placeholder]");
        if (timestamp && data.signed_at) {
          timestamp.textContent = `Signed: ${data.signed_at}`;
        }

        // Update image
        const img = template.querySelector('[data-signature-url-placeholder]');
        img.src = data.signature_url;
        img.alt = `${this.currentRole} Signature`;

        // Update button
        const button = template.querySelector('[data-signature-id-placeholder]');
        button.dataset.signaturesId = data.signature_id;
        button.dataset.signaturesRole = this.currentRole;

        // Clear and append
        signatureField.innerHTML = '';
        signatureField.appendChild(template);

        this.closeModal();
      } else {
        const errorMessage = data.errors ? data.errors.join(", ") : "Unknown error occurred";
        throw new Error(errorMessage);
      }
    }).catch(error => {
      console.error("Error:", error);
      alert(`Error saving signature: ${error.message}`);
    }).finally(() => {
      // Enable buttons
      this.toggleSaveButton(true);
      this.toggleCloseButton(true);
    });;
  }

  deleteSignature(event) {
    // Prevent deletion if truck visit is completed
    if (this.isCompletedValue) {
      alert("Signatures cannot be deleted after the truck has gated out.");
      return;
    }

    const role = event.target.dataset.signaturesRole;
    const signatureId = event.target.dataset.signaturesId;
    const deleteUrl = `${this.element.dataset.signaturesBaseDeleteUrl}/${signatureId}`;

    if (!confirm(`Are you sure you want to delete the ${role} signature?`)) return;

    fetch(deleteUrl, {
      method: "DELETE",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
      },
    }).then(response => {
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      return response.json();
    }).then(data => {
      if (data.status === "success") {
        const signatureField = event.target.closest(".signature-field");

        // Clone the template
        const updatedTemplate = this.createUnsignedTemplate(role);

        // Replace the content with the updated template
        signatureField.innerHTML = '';
        signatureField.appendChild(updatedTemplate);
      } else {
        alert(`Failed to delete signature: ${data.message}`);
      }
    }).catch(error => {
      console.error("Error deleting signature:", error);
      alert("An error occurred while deleting the signature.");
    });
  }

  createUnsignedTemplate(role) {
    // Clone the unsigned template
    const template = this.unsignedTemplateTarget.content.cloneNode(true);

    // Update the outer span
    const outerSpan = template.querySelector("[data-role-text-placeholder]");
    outerSpan.dataset.signaturesRole = role; // Set the role dynamically
    outerSpan.firstChild.textContent = `${role || '[ROLE]'}: `; // Update the text before the nested span

    // Update the nested span
    const nestedSpan = template.querySelector(".click-to-sign-text");
    nestedSpan.textContent = this.element.dataset.clickToSignText || "Click to sign";

    return outerSpan;
  }

  toggleSaveButton(enable) {
    const saveButton = this.element.querySelector("button[data-action='click->signatures#saveSignature']");
    if (enable) {
      saveButton.disabled = false;
      saveButton.classList.remove("disabled-button");
    } else {
      saveButton.disabled = true;
      saveButton.classList.add("disabled-button");
    }
  }
  toggleCloseButton(enable) {
    // Disable all close buttons
    const closeButtons = this.element.querySelectorAll("button[data-action='click->signatures#closeModal']");
    closeButtons.forEach((button) => {
      if (enable) {
        button.disabled = false;
        button.classList.remove("disabled-button");
      } else {
        button.disabled = true;
        button.classList.add("disabled-button");
      }
    });
  }
}
