import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    scheduledReportId: String,
    reportClass: String
  }

  connect() {
    if (this.scheduledReportIdValue) {
      const form = document.querySelector('#report-form form')
      if (!form) return

      const input = document.createElement('input')
      input.type = 'hidden'
      input.name = 'scheduled_report_id'
      input.value = this.scheduledReportIdValue
      form.appendChild(input)
    }
  }

  submit(event) {
    event.preventDefault()

    // Find the form within the report-form div
    const form = document.querySelector('#report-form form')
    if (!form) return

    // Add appropriate parameters based on whether it's new or update
    if (this.scheduledReportIdValue) {
      // Update existing scheduled report
      const input = document.createElement('input')
      input.type = 'hidden'
      input.name = 'update_scheduled_report_id'
      input.value = this.scheduledReportIdValue
      form.appendChild(input)
    } else {
      // Create new scheduled report
      const createInput = document.createElement('input')
      createInput.type = 'hidden'
      createInput.name = 'create_scheduled_report'
      createInput.value = 'true'
      form.appendChild(createInput)

      // Add report_class parameter
      const reportClassInput = document.createElement('input')
      reportClassInput.type = 'hidden'
      reportClassInput.name = 'report_class'
      reportClassInput.value = this.reportClassValue
      form.appendChild(reportClassInput)
    }

    // Submit the form
    form.submit()
  }
}
